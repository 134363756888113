<template>
  <div>
    <el-popover
      style="padding: 10px"
      placement="bottom-end"
      :width="isMobileScreen ? '90%':'500'"
      trigger="click">
      <div class="personal-center">
        <img
          style="height: 60px"
          src="@/assets/images/avatar.png"
          alt="">
        <span>{{ $t('welcome') }},{{ username }}</span>
        <div>
          <el-button
            type="primary"
            :size="isMobileScreen ? 'mini' : 'small'"
            round
            @click="changePasswordVisible=true">
            {{ $t('changePsw') }}
          </el-button>
          <el-button
            type="primary"
             :size="isMobileScreen ? 'mini' : 'small'"
            round
            @click="changeEmailVisible=true">
            {{ $t('changeEmail') }}
          </el-button>
          <el-button
            type="danger"
            :size="isMobileScreen ? 'mini' : 'small'"
            round
            @click="logout">
            {{ $t('signOut') }}
          </el-button>
        </div>
      </div>
      <span
        slot="reference"
        :style="{color:color}">
      <div class="personal">
        <el-avatar
          :size="22"
          :src="require('@/assets/images/avatar.png')">
        </el-avatar>
        <span>{{ username }}</span>
        <span><em class="el-icon-caret-bottom"></em></span>
      </div>
    </span>
    </el-popover>
    <change-password-dialog
      v-if="changePasswordVisible"
      :visible="changePasswordVisible"
      @closed="changePasswordVisible=false">
    </change-password-dialog>
    <change-email-dialog
      v-if="changeEmailVisible"
      :visible="changeEmailVisible"
      @closed="changeEmailVisible=false">
    </change-email-dialog>
  </div>
</template>

<script>
import ChangePasswordDialog from '../ChangePasswordDialog'
import ChangeEmailDialog from '../ChangeEmailDialog'
import { resetRouter } from '@/router'
import { mapState } from "vuex";
export default {
  name: 'PersonalCenterPopover',
  components: { ChangePasswordDialog, ChangeEmailDialog },
  props: {
    username: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      changePasswordVisible: false,
      changeEmailVisible: false
    }
  },
  computed: {
    ...mapState({
      isMobileScreen: "isMobileScreen",
    }),
    color () {
      return this.$store.state.currentTheme.barTextColor
    }
  },
  methods: {
    /**
     * 退出登录，清除各种数据
     */
    logout () {
      this.$request.post({
        url: this.$apis.logout
      })
      // 重置vuex的数据
      this.$store.dispatch('resetState')
      // 去除主题数据及用户名之外的其他所有缓存数据
      const language = localStorage.language || this.$i18n.locale
      const currentTheme = JSON.stringify(this.$store.state.currentTheme)
      const username = localStorage.username || ''
      localStorage.clear()
      sessionStorage.clear()
      // 缓存主题
      localStorage.currentTheme = currentTheme
      // 缓存语言
      localStorage.language = language
      // 如果用户勾选了记住用户，则缓存用户名
      localStorage.username = username
      // 重置路由
      resetRouter()
      this.$router.replace({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-popover {
  .personal-center {
    @include flex-center-column;
    // padding: 20px;

    > span {
      display: inline-block;
      width: 100%;
      margin-top: 20px;
      padding: 10px;
      border-radius: 5px;
      background-color: #F2F6FC;
    }

    div {
      margin-top: 20px;
    }
  }
}

.el-popover__reference {
  .personal {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 10px;
    border-radius: 20px;
    > span {
      display: inline-block;
      height: 100%;
      margin: auto 4px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
</style>
